export const TIME_DELAY = 1_000; // ms
export const SCROLL_DISTANCE = '80vh';
export const IS_LOGGED_IN = false;
export const SHOW_BANNER_CONDITION = () => true;

// Banner text constants
export const COUPON_CODE = 'HOLIDAY30';
export const DEAL_TEXT = 'HOLIDAY FLASH SALE';
export const PERCENTAGE = '30%';
export const DISCOUNT_TEXT = `Save ${PERCENTAGE} on ANY plan with code ${COUPON_CODE}`;
export const BUTTON_TEXT = 'Act Now';
export const BUTTON_TEXT_BANNER = 'Act Now: Holiday Flash Sale';

export const TIME_LEFT = 1 * 24 * 60 * 60 + 11 * 60 * 60 + 24 * 60 + 55; // 1 day + 11 hours + 24 minutes + 55 seconds
export const TARGET_DATE = new Date('2024-12-04T07:59:00Z'); // 11:59 PM PST is 7:59 AM UTC
export const IS_USE_TARGET_DATE = false;

export const SHOW_CLOSE_ICON = false;
export const BANNER_TIMER_STORAGE_KEY = 'bannerTimeLeft';
