export enum SliderDirection {
  All = 'all',
  Above = 'above',
  Below = 'below',
}

export const MIN_VALUE = 0;
export const MAX_VALUE = 9007199254740991;
export const NEGATIVE_MIN_VALUE = -9007199254740991;

export const MIN_PRICE = 0.5;
export const MAX_PRICE = 50;

export const MIN_OTM = 0;
export const MAX_OTM = 100;

export const MIN_FLOW = 50;
export const MAX_FLOW = 100;

export const MIN_MOMENTUM = 0;
export const MAX_MOMENTUM = 10;

export const MIN_SIZE = 500;
export const MAX_SIZE = 10000;

export const MIN_PREMIUM = 20000;
export const MAX_PREMIUM = 1000000;

export const MIN_VOLATILITY = 25;
export const MAX_VOLATILITY = 100;

export const MIN_OPEN_INTEREST = 0;
export const MAX_OPEN_INTEREST = 75000;

export const MIN_TIME = 0;
export const MAX_TIME = 131500; //3 months in minutes

export const MIN_MARKET_CAP = 300000000;
export const MAX_MARKET_CAP = 200000000000;

export type State = typeof EQUITY_FILTER;

export enum PresetType {
  Equities = 0,
  Unusual = 1,
  Sweeps = 2,
  Momentum = 3,
  ETFs = 4,
  Moonshots = 5,
  Custom = 6,
}

export const isSimilarObject = (referenceObject: any, comparedObject: any) => {
  if (!referenceObject || !comparedObject) {
    return false;
  }

  let isSimilar = true;

  Object.keys(referenceObject).forEach((key) => {
    const referenceValue = referenceObject[key];
    const comparedValue = comparedObject[key];

    if (Array.isArray(referenceValue) && Array.isArray(comparedValue)) {
      if (!isSimilarObject(referenceValue, comparedValue)) {
        console.log('NOT SIMILAR ARRAY', key, referenceValue, comparedValue);
        isSimilar = false;
      }
    } else if (referenceValue !== comparedValue) {
      console.log('NOT SIMILAR VALUE', key, referenceValue, comparedValue);
      isSimilar = false;
    }
  });

  return isSimilar;
};

export const EQUITY_FILTER = {
  isPreciseFiltersEnabled: false,
  tt: 'Stocks',
  minFlow: MIN_VALUE,
  maxFlow: MAX_VALUE,
  minPrice: MIN_VALUE,
  maxPrice: MAX_VALUE,
  minOtm: NEGATIVE_MIN_VALUE,
  maxOtm: MAX_VALUE,
  minMomentum: MIN_VALUE,
  maxMomentum: MAX_VALUE,
  topPositions: false,
  ultrashort: false,
  short: false,
  medium: false,
  long: false,
  minSize: MIN_VALUE,
  maxSize: MAX_VALUE,
  minPremium: MIN_VALUE,
  maxPremium: MAX_VALUE,
  minImpVolatility: MIN_VALUE,
  maxImpVolatility: MAX_VALUE,
  minOpenInterest: MIN_VALUE,
  maxOpenInterest: MAX_VALUE,
  activeSector: ['All'],
  minTime: MIN_VALUE,
  maxTime: MAX_VALUE,
  minMarketCap: MIN_VALUE,
  maxMarketCap: MAX_VALUE,
  expiration: 'All',
  tradeType: ['All'],
  optionType: 'All',
  typedp: ['All'],
  minSizeDp: MIN_VALUE,
  maxSizeDp: MAX_VALUE,
  minAmtDp: MIN_VALUE,
  maxAmtDp: MAX_VALUE,
  minExpiration: MIN_VALUE,
  maxExpiration: MAX_VALUE,
  expirationDirection: SliderDirection.All,
  otmDirection: SliderDirection.All,
  priceDirection: SliderDirection.All,
  flowDirection: SliderDirection.All,
  momentumDirection: SliderDirection.All,
  sizeDirection: SliderDirection.All,
  premiumDirection: SliderDirection.All,
  ivDirection: SliderDirection.All,
  interestDirection: SliderDirection.All,
  marketCapDirection: SliderDirection.All,
  timeDirection: SliderDirection.All,
  sizeDpDirection: SliderDirection.All,
  amtDpDirection: SliderDirection.All,
};
export const isEquityPreset = (comparedObject: any) =>
  isSimilarObject(EQUITY_FILTER, comparedObject);

export const UNUSUAL_FILTER = {
  ...EQUITY_FILTER,
  tradeType: ['Unusual'],
  minFlow: 0,
  maxPrice: MAX_VALUE,
  minOtm: MIN_VALUE,
  minMomentum: MIN_VALUE,
  topPositions: false,
  ultrashort: false,
  short: false,
  medium: false,
  long: false,
};
export const isUnusualPreset = (comparedObject: any) =>
  isSimilarObject(UNUSUAL_FILTER, comparedObject);

export const SWEEP_FILTER = {
  ...EQUITY_FILTER,
  tradeType: ['Sweeps'],
  minFlow: 0,
  maxPrice: MAX_VALUE,
  minOtm: MIN_VALUE,
  minMomentum: MIN_VALUE,
  topPositions: false,
  ultrashort: false,
  short: false,
  medium: false,
  long: false,
};
export const isSweepPreset = (comparedObject: any) => isSimilarObject(SWEEP_FILTER, comparedObject);

export const MOMENTUM_FILTER = {
  ...EQUITY_FILTER,
  minFlow: 0,
  maxPrice: MAX_VALUE,
  minOtm: MIN_VALUE,
  minMomentum: 2,
  topPositions: false,
  ultrashort: false,
  short: false,
  medium: false,
  long: false,
};
export const isMomentumPreset = (comparedObject: any) =>
  isSimilarObject(MOMENTUM_FILTER, comparedObject);

export const ETF_FILTER = {
  ...EQUITY_FILTER,
  tt: 'ETFs',
  minFlow: 0,
  maxPrice: MAX_VALUE,
  minOtm: MIN_VALUE,
  minMomentum: MIN_VALUE,
  topPositions: false,
  ultrashort: false,
  short: false,
  medium: false,
  long: false,
};
export const isEtfPreset = (comparedObject: any) => isSimilarObject(ETF_FILTER, comparedObject);

export const MOOSHOT_FILTER = {
  ...EQUITY_FILTER,
  maxFlow: 20, // This is now put flow, so 95% or more calls
  maxPrice: 3,
  minOtm: 10,
  minMomentum: MIN_VALUE,
  topPositions: false,
  ultrashort: true,
  short: true,
  medium: true,
  long: false,
};
export const isMoonshotPreset = (comparedObject: any) =>
  isSimilarObject(MOOSHOT_FILTER, comparedObject);

export const presetObjects = {
  [PresetType.Equities]: EQUITY_FILTER,
  [PresetType.Unusual]: UNUSUAL_FILTER,
  [PresetType.Sweeps]: SWEEP_FILTER,
  [PresetType.Momentum]: MOMENTUM_FILTER,
  [PresetType.ETFs]: ETF_FILTER,
  [PresetType.Moonshots]: MOOSHOT_FILTER,
  [PresetType.Custom]: null,
};

export const presetAggregateSortKey = {
  [PresetType.Equities]: 'otm',
  [PresetType.Unusual]: 'unusualOtm',
  [PresetType.Sweeps]: 'sweepContracts',
  [PresetType.Momentum]: 'momentum',
  [PresetType.ETFs]: 'contracts',
  [PresetType.Moonshots]: 'contracts',
  [PresetType.Custom]: null,
};

export const presetGraphIndex = {
  [PresetType.Equities]: 0,
  [PresetType.Unusual]: 1,
  [PresetType.Sweeps]: 9,
  [PresetType.Momentum]: 2,
  [PresetType.ETFs]: 5,
  [PresetType.Moonshots]: 5,
  [PresetType.Custom]: null,
};
